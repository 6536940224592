import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import logo_dark from "../assets/images/logo_dark.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//import api
import { useTheme } from "./ThemeContext";
import { toastAlert } from "../lib/toastAlert";

export default function Footer() {
  //redux
  const { isAuth } = useSelector((state) => state.auth);
  const siteDoc = useSelector((state) => state.siteSetting)


  const { theme } = useTheme();

  const { isStake, isTrade } = useSelector((state) => state.account);

  const handleTrade = () => {
    if (!isTrade) {
      toastAlert("error", "Trade Access Blocked", "login");
    }

  }

  const handleStake = () => {
    if (!isStake) {
      toastAlert("error", "Stake Access Blocked", "login");
    }
  }

  // console.log(theme, 'themetheme')



  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel_top">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <Link className="footer_logo" to="/">
                {theme == "dark" ? (
                  <img
                    src={logo}
                    className="img-fluid mb-4"
                    alt="Light logo"
                  />
                ) : (
                  <img
                    src={logo_dark}
                    className="img-fluid mb-4"
                    alt="Dark logo"
                  />
                )}
              </Link>
              <p className="footer_abt_content">
                Let's get yout money back!
                <br /> If you've been ripped off by scammers, get in touch and our team of experts will work to get your money back{" "}
              </p>
            </div>
            <div className="col-lg-3 col-md-3">
              <h3>Quick Links</h3>
              <ul className="footer_quick_links">
                {
                  siteDoc?.stakeAccess &&
                  <li>
                    <Link to={isAuth && isStake ? "/staking" : "/login"} onClick={handleStake}>
                      Staking
                    </Link>
                  </li>
                }
                {/* {
                  siteDoc?.tradeAccess &&
                  <li>
                    <Link to={isAuth && isTrade ? "/trade" : "/login"} onClick={handleTrade}>Trade</Link>
                  </li>
                } */}
                {
                  siteDoc?.walletAccess &&
                  <>
                    <li>
                      <Link to={isAuth == true ? "/wallet" : "/login"}>Wallet</Link>
                    </li>
                    {/* <li>
                      <Link to={isAuth == true ? "/wallet" : "/login"}>
                        Exchange
                      </Link>
                    </li> */}
                  </>
                }
                <li>
                  <Link to={isAuth == true ? "/support-ticket" : "/login"}>
                    Support Ticket
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3">
              <h3>Follow us</h3>
              <ul className="footer_social_links">
                {/* {siteDoc && siteDoc.facebookLink && (
                  <li>
                    <a href={siteDoc.facebookLink} target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 320 512"
                      >
                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                      </svg>
                    </a>
                  </li>
                )} */}
                {/* {siteDoc && siteDoc.twitterUrl && (
                  <li>
                    <a href={siteDoc.twitterUrl} target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                      </svg>
                    </a>
                  </li>
                )} */}
                {/* {siteDoc && siteDoc.telegramlink && (
                  <li>
                    <a href={siteDoc.telegramlink} target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" />
                      </svg>
                    </a>
                  </li>
                )} */}
                {/* {siteDoc && siteDoc.linkedinLink && (
                  <li>
                    <a href={siteDoc.linkedinLink} target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                      </svg>
                    </a>
                  </li>
                )} */}
                {siteDoc && siteDoc.whatsappLink && (
                  <li>
                    <a href={siteDoc.whatsappLink} target="_blank">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                      </svg> */}
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.25 27.7422L2.21904 20.3996C0.633525 17.5394 0.135306 14.2049 0.815807 11.0079C1.49631 7.81089 3.30989 4.96589 5.92373 2.995C8.53756 1.0241 11.7763 0.0595429 15.0457 0.278266C18.315 0.496989 21.3956 1.88433 23.7222 4.18575C26.0487 6.48716 27.4651 9.54825 27.7114 12.8073C27.9578 16.0663 27.0176 19.3046 25.0633 21.9279C23.109 24.5512 20.2718 26.3837 17.0723 27.0889C13.8728 27.7941 10.5257 27.3247 7.64511 25.767L0.25 27.7422ZM8.08144 23.0219L8.55132 23.3009C10.8786 24.6883 13.6345 25.178 16.2989 24.6775C18.9633 24.1771 21.3519 22.7212 23.0138 20.5846C24.6758 18.4479 25.4961 15.7784 25.3199 13.0799C25.1438 10.3813 23.9834 7.84042 22.0577 5.93676C20.132 4.0331 17.5744 2.89837 14.8674 2.74674C12.1604 2.59511 9.49141 3.43709 7.36417 5.11373C5.23693 6.79037 3.79861 9.18569 3.32066 11.8476C2.84271 14.5095 3.3582 17.2539 4.76985 19.5626L5.04954 20.0313L3.93077 24.1267L8.08144 23.0219Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.2708 15.7355C19.0032 15.5157 18.688 15.3611 18.3502 15.2836C18.0124 15.2061 17.6611 15.208 17.3241 15.2891C16.8207 15.5011 16.4962 16.2934 16.2054 16.6951C16.1342 16.7884 16.0319 16.8531 15.9169 16.8777C15.802 16.9022 15.6821 16.885 15.5789 16.8291C13.7281 16.1043 12.1756 14.7797 11.1709 13.0684C11.085 12.9606 11.0441 12.8239 11.0566 12.6868C11.0691 12.5497 11.134 12.4226 11.238 12.332C11.4534 12.176 11.6358 11.9792 11.7749 11.7529C11.914 11.5266 12.007 11.2751 12.0485 11.0129C12.0901 10.7507 12.0794 10.4829 12.0171 10.2248C11.9549 9.96667 11.8422 9.72339 11.6855 9.50877C11.417 8.93966 11.1261 8.12502 10.5667 7.80141C10.2938 7.67304 9.98904 7.62786 9.69051 7.67153C9.39198 7.7152 9.11302 7.84578 8.88857 8.04691C8.49829 8.38071 8.1881 8.79766 7.98092 9.26698C7.77373 9.7363 7.67482 10.246 7.69147 10.7586C7.70074 11.0479 7.73822 11.3357 7.80336 11.6178C7.98355 12.2876 8.26257 12.9269 8.63126 13.5148C8.89084 13.9711 9.17854 14.4109 9.49271 14.8316C10.5053 16.2122 11.7738 17.3864 13.2294 18.2909C13.9641 18.7498 14.7485 19.1241 15.5677 19.4068C16.4194 19.7891 17.3574 19.9392 18.2863 19.842C18.8129 19.7542 19.3109 19.5424 19.739 19.2241C20.1671 18.9058 20.5129 18.4902 20.7476 18.0119C20.8788 17.7179 20.9178 17.3911 20.8595 17.0746C20.6022 16.4273 19.7295 16.0368 19.2708 15.7355Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_panel_bottom">
          <p>
            Copyright © {new Date().getFullYear()} <a href="/">StakingClaim</a>, All
            rights Reserved
          </p>
          <ul className="footer_menu_links">
            <li>
              <Link to="/terms">Terms of service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
